export default {
    state() {
        return {
            list_of_monitors: null
        }
    },
    mutations: {
        setListOfMonitors(state, payload) {
            state.list_of_monitors = payload.data
        }
    },
    actions: {
        async saveMonitor({getters}, payload) {
            await fetch(getters.mainDatabase + 'monitor/' + payload.name + getters.databaseEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload.data)
            })
        },
        async getListOfMonitors({getters, commit}) {
            try {
            const response = await fetch(getters.mainDatabase + 'monitor' + getters.databaseEndpoint) 
            const responseData = await response.json()
            commit('setListOfMonitors', {data: responseData})
            } catch {
                commit('setListOfMonitors', {data: []})
            }
        },
        async deleteMonitor({getters}, payload) {
            await fetch(getters.mainDatabase + 'monitor/' + payload.name + getters.databaseEndpoint, {
                method: 'DELETE'
            })
        }
    },
    getters: {
        listOfMonitors(state) {
            return state.list_of_monitors
        },
        namesOfMonitors(state) {
            return state.list_of_monitors ? Object.keys(state.list_of_monitors) : []
        }
    }
}