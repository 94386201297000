let scrollInterval = null

export default {
    state() {
        return {
            simulation_time: 0,
            sidebar_visible: false,
            actions_to_do: [],
            sequence_comments: [],
            sequences_list: null,
            active_sequence: null,
            autoscroll: true,
            interupt: null,
            saved: true
        }
    },
    mutations: {
        setSimulationTime(state, payload) {
            state.simulation_time = payload.simulation_time
        },
        setSidebar(state, payload) {
            state.sidebar_visible = payload.visible
        },
        setActionsToDo(state, payload) {
            state.actions_to_do = payload.actions_to_do
            state.sequence_comments = payload.comments
        },
        setActionDone(state, payload) {
            state.actions_to_do[payload.index].is_done = payload.status
        },
        setListOfSequences(state, payload) {
            state.sequences_list = payload.sequences_list
        },
        setActiveSequence(state, payload) {
            state.active_sequence = payload
        },
        setSequenceError(state, payload) {
            state.active_sequence.error = payload.error
        },
        updateAutoscroll(state, payload) {
            state.autoscroll = payload
        },
        setInterupt(state, payload) {
            state.interupt = payload
        },
        setSaved(state, payload) {
            state.saved = payload
        }
    },
    actions: {

        clearSequence({commit}) {
            commit('setActionsToDo', {actions_to_do: [], comments: []})
            commit('setActiveSequence', null)
        },

        async runSequence({getters, dispatch, commit, state}, sequence_name) {

            if (state.sequences_list[sequence_name.label] === undefined) {
                console.error('Sequence not found')
                return false
            }

            const date = Date.now()
            commit('setInterupt', date)

            if (scrollInterval) {
                clearInterval(scrollInterval)
                scrollInterval = null
            }
            scrollInterval = setInterval(() => {
                const currentStep = document.querySelector('.current-step');
                if (!state.autoscroll) {
                    return
                }
                if (currentStep) {
                    currentStep.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 500);

            commit('setActionsToDo', {actions_to_do: [], comments: []})
            if (sequence_name) {
                commit('setActiveSequence', {error: null, name: sequence_name, data: state.sequences_list[sequence_name.label]})
            }
            commit('setSequenceError', {error: null})

            var init_signal_list = JSON.parse(JSON.stringify(state.active_sequence.data))

            let infiniteLoopCounter = 0

            //loop po liscie
            for (let index = 0; index < init_signal_list.length; ) {

                       
                //warunek kiedy chce przejść przez liste
                if (init_signal_list[index].status1 === 'Run subsequence') {
                    
                    infiniteLoopCounter++
                    if (infiniteLoopCounter > 150) {
                        commit('setSequenceError', {error: 'Infinite loop detected, check your sequence'})  
                        throw new Error('Infinite loop detected, check your sequence')
                    } 
                    

                    const subsequence = getters.sequencesList[init_signal_list[index].status2.label]

                    if (subsequence === undefined) {
                        commit('setSequenceError', {error: 'One or more subsequences not exist, check your sequence.'})  
                        throw new Error('One or more subsequences not exist, check your sequence.')
                    }
                    //przypisanie wybranej subsekwencji do zmiennej 
                    let temp_list = JSON.parse(JSON.stringify(subsequence));


                    //mapa po tej liście obiektów
                    temp_list.map((el => {
                        el.time += init_signal_list[index].time
                    }))

                    //usuwam element z sekwencją
                    init_signal_list.splice(index, 1)

                    //merguje tablice
                    init_signal_list = [...init_signal_list, ...temp_list]
                    //sortuje całą tablice
                    init_signal_list.sort((a,b) => {
                        return a.time < b.time ? -1 : 1
                    })
                    temp_list = []
                    index = 0
                } else {
                    index++
                }

            }

            //get current time of simulation
            await dispatch('stopSimulation')
            await dispatch('calculateSimulationTime')
            
            //counter of time for next step
            var simulation_interval = 0
            
            const comments = init_signal_list.filter(item => item.status1 === 'comment')
            init_signal_list = init_signal_list.filter(item => item.status1 !== 'comment')

            
            
            
            //add is_done property to actions to do
            let data_formated = []
            for (const action of init_signal_list) {
                let action_formated = action
                action_formated['is_done'] = false
                data_formated.push(action_formated)
            }

            //save actions to do
            commit('setActionsToDo', {actions_to_do: data_formated, comments: comments})
            let next_step_time = Number(getters.simulationTime)
            if (init_signal_list[0].time > 0) {
                next_step_time += Number(init_signal_list[0].time)
                await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=" + init_signal_list[0].time * 10000)
            }



            //loop for all actions in sequence
            for (let index = 0; index < init_signal_list.length;) {

                if (state.interupt > date) {
                    console.log('Sequence end, due to new active sequence')
                    break
                }

                if (init_signal_list[index].status1 === 'comment') {
                    if (index === init_signal_list.length - 1) {
                        await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=-1")
                        // commit('setSidebar', {visible: false})
                        console.log('Sequence end')
                        break
                    }
                    index++
                    continue
                }

                //check if simulation time is equal to next step time
                // console.table({simutime: getters.simulationTime, nextstep: next_step_time})
                if (getters.simulationTime >= next_step_time) {
                    //action for last action in sequence
                    if (index === init_signal_list.length - 1) {
                        
                        console.log(`Seting signal: ${init_signal_list[index].status2.label} with value: ${init_signal_list[index].status3} requested time: ${init_signal_list[index].time}`)
                        try {
                        await dispatch('setSignal', {
                            signal_name: init_signal_list[index].status2.label,
                            val: init_signal_list[index].status3,
                            cycles: -1
                        })
                        commit('setActionDone', {index: index, status: 'done'})
                        }

                        catch(error) {
                            console.error(`Failed when setting ${init_signal_list[index].status2.label} with value: ${init_signal_list[index].status3}`)
                            commit('setActionDone', {index: index, status: 'failed'})
                        }
                        
                        console.log(`Evaluating simulation for ${1} cycle / ${0.05} seconds`)

                        await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=-1")
                        // commit('setSidebar', {visible: false})
                        console.log('Sequence end')
                        break
                    }

                    //action when time n+1 == n
                    if ((init_signal_list[index].time === init_signal_list[index + 1].time) && init_signal_list[index + 1].status1 !== 'comment') {
                        console.log(`Seting signal: ${init_signal_list[index].status2.label} with value: ${init_signal_list[index].status3} requested time: ${init_signal_list[index].time}`)

                        try {
                            await dispatch('setSignal', {
                                signal_name: init_signal_list[index].status2.label,
                                val: init_signal_list[index].status3,
                                cycles: -1
                            })
                            commit('setActionDone', {index: index, status: 'done'})
                        }
                        catch(error) {
                            console.error(`Failed when setting ${init_signal_list[index].status2.label} with value: ${init_signal_list[index].status3}`)
                            commit('setActionDone', {index: index, status: 'failed'})
                        }
                        index++
                    }

                    //action when time n+1 != n
                    else {
                        console.log(`Seting signal: ${init_signal_list[index].status2?.label} with value: ${init_signal_list[index].status3} requested time: ${init_signal_list[index].time}`)

                        try {
                            await dispatch('setSignal', {
                                signal_name: init_signal_list[index].status2.label,
                                val: init_signal_list[index].status3,
                                cycles: -1
                            })
                            commit('setActionDone', {index: index, status: 'done'})
                        } catch (error) {
                            console.error(`Failed when setting ${init_signal_list[index].status2?.label} with value: ${init_signal_list[index].status3}`)
                            commit('setActionDone', {index: index, status: 'failed'})
                        }
                        

                        //calculate for how much cycles evaluate simulation
                        const list_without_comments = init_signal_list.filter(item => item.status !== 'comment')
                        simulation_interval = list_without_comments[index + 1].time - list_without_comments[index].time

                        //get gurrent time of simulatio
                        await dispatch('calculateSimulationTime')

                        //calculate when next step should evaluate
                        next_step_time = (Number(getters.simulationTime) + simulation_interval)


                        console.log(`Evaluating simulation for ${simulation_interval * 10000} cycles / ${simulation_interval} seconds`)

                        //evaluate simulation
                        await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=" + (simulation_interval * 10000))
                        index++

                    }
                //monitor simulation time
                } else {
                    await dispatch('calculateSimulationTime')
                    await new Promise((resolve) => {
                        setTimeout(resolve(), 200)
                    })
                    continue
                }
            }
            clearInterval(scrollInterval)
            scrollInterval = null
            commit('setInterupt', false)
            return true
        },
        async calculateSimulationTime({commit, getters}) {
            try {
                const response = await fetch(getters.simulationIp + "v2/modelconfig?method=json")
                const responseData = await response.json()
                const LTime = responseData[0].value
                const MTime = responseData[1].value
                const simulationTime = ((MTime * 65536 + LTime) * 0.005).toFixed(5)
                commit('setSimulationTime', {simulation_time: simulationTime})
            } catch(err) {
                console.log('Failed to get simulation time')
            }
        },
        // CRUD
        async getListOfSequences({commit, getters}) {
            const response = await fetch(getters.firebase_simu_url + '/sequencer' + getters.databaseEndpoint) 
            const responseData = await response.json()
            commit('setListOfSequences', {sequences_list: responseData})
        },
        async createSequence({getters}, payload) {
            await fetch(getters.firebase_simu_url + '/sequencer/' + payload.sequence_name + getters.databaseEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload.data)
            })
        },
        async saveSequence({getters}, payload) {
            await fetch(getters.firebase_simu_url + '/sequencer/' + payload.sequence_name + getters.databaseEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload.data)
            })
        },
        async deleteSequence({getters}, payload) {
            await fetch(getters.firebase_simu_url + '/sequencer/' + payload.sequence_name + getters.databaseEndpoint, {
                method: 'DELETE'
            })
            console.log('done')
        }

    },
    getters: {
        simulationTime(state) {
            return state.simulation_time
        },
        sidebarVisible(state) {
            return state.sidebar_visible
        },
        actionsToDo(state) {
            return state.actions_to_do
        },
        sequencesListKeys(state) {
            return state.sequences_list ? Object.keys(state.sequences_list) : []
        },
        // tutaj dokończyć 
        sequencesList(state) {
            return state.sequences_list
        },
        activeSequence(state) {
            return state.active_sequence
        }, 
        lastAction(_, getters) {
            return getters.actionsToDo.filter(action => action.is_done === 'done').slice(-1)[0];
        },
        sequenceComments(state) {
            return state.sequence_comments
        },
        activeComment(state, getters) {
            if (!state.sequence_comments.length) {
                return null
            }
            if (!getters.actionsToDo.length || getters.actionsToDo.every(action => action.is_done === 'done')) {
                return null
            }
            if (!getters.lastAction) {
                return null
            } else {
                return state.sequence_comments.filter(comment => comment.time <= getters.lastAction.time).slice(-1)[0].value
            }
        },
        autoScroll(state) {
            return state.autoscroll
        },
        isSaved(state) {
            return state.saved
        }

    }
}