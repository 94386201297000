<template>
    <div>
        <div class="backdrop" v-if="open" @click="$emit('close')"></div>
        <transition name="modal">
            <dialog open v-if="open" :style="{width: width + 'rem', left: 'calc(50% - '+width/2+'rem)', 'min-height': height + '%', top: top + 'vh'}">
                <slot></slot>
            </dialog>
        </transition>
    </div>            
</template>

<script>
export default {
    props: ['open','top','width', 'height']
}
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 60;
    background-color: rgba(0, 0, 0, 0.75);
}
dialog {
    position: fixed;
    top: 5vh;
    left: calc(50% - 15rem);
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    padding: 1rem;
    background-color: var(--color2);
    z-index: 70;
    border: none;
    max-height: 80vh;
    overflow: auto;
}
dialog::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    }
dialog::-webkit-scrollbar-thumb {
    background: var(--color7) !important;
    border-radius: 10px;
}
dialog::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.modal-enter-active {
        animation: modal-in 0.4s ease-out;
}
.modal-leave-active {
    animation: modal-out 0.4s ease-in;
}

@keyframes modal-in {
    from {
        opacity: 0;
        transform: scale(0.8)
    }
    to {
        opacity: 1;
        transform: scale(1)
    }
}
@keyframes modal-out {
    from {
        opacity: 1;
        transform: scale(1)
    }
    to {
        opacity: 0;
        transform: scale(0.8)
    }
}

</style>