import { storage } from '@/firebaseConfig';
import {  ref, getBlob } from "firebase/storage";


export default {
    state() {
        return {}
    },
    mutations: {
    },
    actions: {
        async downloadPdfFile(_, file) {
            try {
                const blob = await getBlob(ref(storage, `/docs/${file}`))
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank')
            } catch(err) {
                console.log(err)
            }
        }
    },
    getters: {
    }
}