import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from '../firebaseConfig'


const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('../views/LoginPanel.vue'),
    meta: {requiresAuth: false}
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyPolicy.vue'),
    meta: {global: true}
  },
  {
    path:'/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: 'simulation',
        name: 'simulation',
        component: () => import('../views/SimulationScreen.vue'),
      },
      {
        path: 'settings',
        name: 'NewSettings',
        component: () => import('../views/NewSettings.vue'),
      },
      {
        path: 'assets',
        name: 'assets',
        component: () => import('../views/AssetsView.vue'),
      },
      // {
      //   path: 'downloads',
      //   name: 'downloads',
      //   component: () => import('../views/DownloadsView.vue'),
      // },
      {
        path: 'releases',
        name: 'releases',
        component: () => import('../views/Releases.vue'),
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('../views/FAQ(Copy).vue')
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/AccountView.vue')
      }
    ]
  },  
  {
    path: '/monitor/:ipAdress',
    name: 'Monitor',
    props: true,
    component: () => import('../views/MonitorInterface.vue'),
    meta: {requiresAuth: true}
  } 
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.global) {
    next()
  }
  if (to.meta.requiresAuth && await getCurrentUser() ) {
    next()
  }
  else if (to.meta.requiresAuth && !(await getCurrentUser())) {
    next('/')
  } 
  else if (!to.meta.requiresAuth && !(await getCurrentUser())) {
    next()
  }
  else if (!to.meta.requiresAuth && await getCurrentUser()) {
    next('/home/settings')
  }
  else {
    next('/home/settings')
  }
})

export default router
