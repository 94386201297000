import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged, setPersistence, browserSessionPersistence } from 'firebase/auth'


//production
const firebaseConfig = {
    apiKey: "AIzaSyA1DdZjJ6m-4bK6685UL2z9D60yLR_w97M",
    authDomain: "web-simhmi-1a35b.firebaseapp.com",
    databaseURL: "https://web-simhmi-1a35b-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "web-simhmi-1a35b",
    storageBucket: "web-simhmi-1a35b.appspot.com",
    messagingSenderId: "285406303888",
    appId: "1:285406303888:web:173c7c913a5825a6b92a15",
    measurementId: "G-Z4XSC5WBJZ"
};

//development
// const firebaseConfig = {
//   apiKey: "AIzaSyAusV8-vgWujWI2mTlVaoTgkT0sBnGh2A8",
//   authDomain: "dev-web-simhmi.firebaseapp.com",
//   databaseURL: "https://dev-web-simhmi-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "dev-web-simhmi",
//   storageBucket: "dev-web-simhmi.appspot.com",
//   messagingSenderId: "680241223395",
//   appId: "1:680241223395:web:6af4238c7373d4a3e197d6"
// };

const databaseURL = firebaseConfig.databaseURL

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth(app)


//initialize storage
const storage = getStorage(app);


setPersistence(auth, browserSessionPersistence)

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe()
        resolve(user)
      },
      reject
    )
  })
}

export { app, auth, storage, getCurrentUser, databaseURL }