<template>
    <div>
        <div class="background">
            <img class="logo1" :src="darkMode ? require('@/assets/woodward_logo_darkmode.png') : require('@/assets/woodward_logo.png')">
            <img class="logo3" :src="darkMode ? require('@/assets/symbol_darkmode.png') : require('@/assets/symbol_lightmode.png')">
            <img class="logo2" :class="{logo_2_dark: darkMode}" :src="darkMode ? require('@/assets/hmi_logo_darkmode.png') : require('@/assets/hmi_logo.svg')"> 
        </div>   
    </div>          
</template>

<script>
export default {
    computed: {
        darkMode() {
            return this.$store.getters.getTheme === 'theme-dark'
        }
    }
}
</script>

<style>
body {
    margin: 0px;
}
.background {
    /* background-color: rgb(244, 244, 244); */
    background-color: var(--color1);
    padding: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    align-items: center;
}
.logo1 {
    padding: 5px 10px 5px 10px;
    width: 380px;
    height: auto;
}
.logo2 {
    width: 200px;
    height: 85px;
}
.logo3 {
    display: none;
    padding: 5px 10px 5px 10px;
    width: 5.6rem;
}
.logo_2_dark {
    width: 160px;
    height: 85px;
    margin-right: 1.2rem;
}

@media (max-width: 600px) {
    .logo1 {
        display: none;
    }
    .logo3 {
        display: block;
    }
}
</style>