<template>
  <div>
    <div v-if="app_loading" class="loader-backdrop">
      <it-icon class="app-loader-icon" name="settings" color="#900028" />
    </div>
    <router-view />
  </div>
</template>

<script>
import LogoBar from '@/components/navbar/LogoBar.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import BaseDialog from '@/components/ui_items/BaseDialog.vue'
import { auth } from './firebaseConfig'
export default {
  components: {  
    LogoBar,
    NavBar,
    BaseDialog
  },
  data() {
    return {
      activeIndex: null,
      show_reminder: false,
      color: 'red',
      bright_background: '#f1f1f1',
      base_font: '#71777a',
      app_loading: true
    }
  },
  computed: {
      showReminder() {
        return this.$store.getters.isExpired ? true : false
      },
      routerRoute() {
        return this.$route.name
      }
  },
  methods: {
    showLoading() {
      this.app_loading = true
    },
    hideLoading() {
      this.app_loading = false
    },
    
    async simuboxData() {
      try {
        if (this.$route.matched[0]?.name == 'home' && this.$store.getters.vmId !== 'Unasigned') {
          await this.$store.dispatch('getVmStatus')
        }
      } catch(err) {
        console.log('Failed to get simubox data' + err)
      }
      setTimeout(this.simuboxData, 2000);
    },

    async modelData() {
      if (this.$route.name == 'NewSettings' && this.$store.getters.vmStatus == 'running') {
        let promises = []
        promises.push(this.$store.dispatch('getCurrentModel'))
        promises.push(this.$store.dispatch('getModelList'))
        await Promise.all(promises)
      }
      setTimeout(this.modelData, 2000);
    },

    async deviceData() {
      if (this.$route.name == 'NewSettings' && this.$store.getters.vmStatus == 'running' && !['no data', 'none'].includes(this.$store.getters.currentModel.toLowerCase())) {
        await this.$store.dispatch('getDeviceList')
        
      }
      setTimeout(this.deviceData, 2000);
    }
  },
  mounted() {
    this.hideLoading()
    auth.onAuthStateChanged(user => {
      if (user) {
        this.$store.commit('SET_USER', user)
        this.$store.dispatch('getLicenseDetails')
      } else {
        this.$store.commit('SET_USER', null)
      }
    })
    //new
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken()
        this.$store.commit('SET_ACCESS_TOKEN', idToken)
      }
    })
    this.simuboxData()
    this.modelData()
    this.deviceData()
    

  },
  beforeMount() {
    let theme = null
    if (this.$CookieConsent.getUserPreferences().acceptedCategories.includes('performance')) {
      theme = localStorage.getItem('theme')
    }
    if (theme) {
      this.$store.dispatch('setTheme', {theme: theme})
    } else {
      this.$store.dispatch('setTheme', {theme: 'theme-dark'})
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Noto+Serif+Display:ital,wght@1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Antique&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200&family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap');
:root[data-theme="theme-light"] {

  --red: rgb(128,0,2);
  --red1: rgb(197,54,37);
  --red2: rgb(129,28,31);

  --gray: rgb(113,119,122);
  --silver: rgb(202,202,202);

  --green1: rgb(183, 186, 131);
  --green2: #707a1e;
  --green3: rgb(79,88,36);

  --green: var(--green3);

  --blue1: rgb(64,137,189);
  --blue2: rgb(13,61,117);
  --blue3: rgb(15,18,60);

  --orange: rgb(230,118,23);
  --yellow: rgb(255,204,0);

  --woodward_red: #9e211f;
  --woodward_red-lighter: #9e424048;
  --woodward_grey: #71777a;
  --woodward_silver: #CACACA;
  --woodward_green_1: rgb(183,186,131);
  --woodward_green_2: rgb(112,122,30);
  --woodward_green_3: rgb(79,88,36);
  --woodward_orange: rgb(230,118,23);
  --woodward_red-light: #b32523;
  --woodward_blue: rgb(15, 18, 60);
  --woodward_blue-ligher: rgb(13, 61, 117);
  --font_crimson: 'Crimson Text', serif;
  --font_noto: 'Noto Sans', sans-serif;
  --font_zed: 'Zen Antique', serif;
  --font_poppins: 'Poppins', sans-serif;

  --color1: #f4f4f4;
  --color2: #ffffff;
  --color3: #dee2e6;
  --color4: rgb(112,122,30);
  --color5: #8d954c;
  --color6: #b0b3b9;
  --color7: rgb(107, 107, 107);
  --color8: #ffffff;
  --color9: #f4f4f4;
  --color10: #2222223d;
  --color11: var(--green1);
  --font1: #6c6c6d;
  --font2: #9e211f;
  --font3: #224376;

  --border1: #c0c0c0;

  --info: #0288d1;
  --warning: #fbc02d;
  --danger: #d32f2f;

  --disabled-color: rgba(213, 213, 213, 0.379);

  --surface-card: #ffffff;

  --color-secondary: #6f6f6f;
}
:root[data-theme="theme-dark"] {

  --red: rgb(128,0,2);
  --red1: rgb(197,54,37);
  --red2: rgb(129,28,31);

  --gray: rgb(113,119,122);
  --silver: rgb(202,202,202);

  --green1: rgb(183,186,131);
  --green2: rgb(112,122,30);
  --green3: rgb(79,88,36);

  --green: var(--green1);

  --blue0: rgb(100, 173, 225);
  --blue1: rgb(64,137,189);
  --blue2: rgba(13, 62, 117, 0.589);
  --blue3: rgb(15,18,60);

  --orange: rgb(230,118,23);
  --yellow: rgb(255,204,0);

  --woodward_red: #9e211f;
  --woodward_red-lighter: #9e424048;
  --woodward_grey: #71777a;
  --woodward_silver: #CACACA;
  --woodward_green_1: rgb(183,186,131);
  --woodward_green_2: rgb(112,122,30);
  --woodward_green_3: rgb(79,88,36);
  --woodward_orange: rgb(230,118,23);
  --woodward_red-light: #b32523;
  --woodward_blue: rgb(15, 18, 60);
  --woodward_blue-ligher: rgb(13, 61, 117);
  --font_crimson: 'Crimson Text', serif;
  --font_noto: 'Noto Sans', sans-serif;
  --font_zed: 'Zen Antique', serif;
  --font_poppins: 'Poppins', sans-serif;


  --color1: #212425;
  --color2: #1a1d1e;
  --color3: #3e4345;
  --color4: #464c1c;
  --color5: var(--green2);
  --color6: var(--green1);
  --color7: rgb(165, 165, 165);
  --color8: #3e4345;
  --color9: #2e3335;
  --color10: #ffffff2d;
  --color11: var(--green2);
  --font1: var(--silver);
  --font2: var(--red1);
  --font3: var(--blue0);

  --border1: #7a7a7a;

  --surface-card: #20222267;

  --warning: var(--orange);
  --info: var(--blue2);
  --danger: var(--red1);

  --disabled-color: rgba(17, 17, 17, 0.379);

  --color-secondary: '#aec2cb'


}
html {
  scroll-behavior: smooth;
}
body {
    background-color: var(--color2);
}
* {
  box-sizing: border-box !important;
}
.label-font {
  font-family: unset !important;
  font-size: 1rem !important;
}
.loader-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #1f1f1f;
  z-index: 999;
}

.app-loader-icon {
  font-size: 5rem !important;
  animation: 2s loading infinite;
}

@keyframes loading{
    from {
        transform: rotate(0deg);  
    }
    to {
        transform: rotate(360deg);
    }
}
.font-color {
  font-size: 0.95rem;
  color: var(--font1);
}
.logo {
  width: 410px;
}
.wh2 {
  font-family: times, Times New Roman, times-roman, georgia, serif;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #444;
}
.nav-elements {
  display: flex;
  justify-content: space-between;
}
.hr_nav {
  width: 100%;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.font-1-basic {
  font-size: 21px;
  font-family: var(--font_crimson);
  letter-spacing: 0.8px;
  color: #68737a;
}
.font-2-basic {
  font-size: 15px;
  font-family: var(--font_noto);
  color: rgb(39, 38, 38)
}
.font-3-basic {
  font-size: 18px;
  font-family: var(--font_zed);
  letter-spacing: 0.8px;
  color: rgb(100, 100, 100)
}
.font-4-basic {
  font: 95% Arial, Helvetica, sans-serif
}
.font-1-inputs {
  color: #555;
  font: 80% Arial, Helvetica, sans-serif;
}
.font-sans {
  font-family: 'PT Sans Caption', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 27px;
  color: var(--font1); 
}
.warning-color {
  color: #ec1b18;
}
.yellow {
  color: rgb(173, 173, 2)
}
.red {
    color: red;
}
.green {
    color: rgb(87, 145, 0);
}
.wwd-color {
    border-color: #726e6e !important;
    color: #726e6e !important;
}
.wwd-column {
  display: flex;
  flex-direction: column;
}
.wwd-row {
  display: flex;
  flex-direction: row;
}
.wwd-sb {
  display: flex;
  justify-content: space-between;
}
.wwd-sa {
  display: flex;
  justify-content: space-around;
}
.wwd-center {
  justify-content: center;
  align-items: center;
}
.base-card {
  background-color: var(--surface-card) !important;
  color: var(--font1) !important;
}

.p-confirm-popup {
  background-color: var(--color1) !important;
  color: var(--font1) !important;
}
.p-orderlist {
  color: var(--font1) !important;
}
.p-orderlist .p-orderlist-header {
  background-color: var(--color1) !important;
  border-color: var(--color3) !important;
  color: var(--font1) !important;
}
.p-orderlist .p-orderlist-list {
  background-color: var(--color2) !important;
  border-color: var(--color3) !important;
}
.p-orderlist-item {
  color: var(--font1) !important;
}
.p-orderlist-item:hover {
  background-color: var(--color1) !important;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: var(--color9) !important;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background: var(--color3) !important;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  box-shadow: inset 0 0 0 0.15rem var(--color3) !important;
  background: var(--color2) !important;
}
.p-focus {
  background: var(--color3) !important;
}

.p-datatable-thead > tr > th{
  background-color: var(--color1) !important;
  color: var(--font1) !important;
}
.p-datatable .p-datatable-tbody > tr {
  background-color: var(--color2) !important;
  color: var(--font1) !important;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background-color: var(--color1) !important;
  color: var(--font1) !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--color3) !important;
}
.p-datatable .p-datatable-thead > tr > th {
  border-color: var(--color3) !important;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: unset !important;
}
.p-button.p-button-secondary.p-button-text {
  color: var(--color-secondary) !important;
}

.p-button-success {
  background-color: var(--color4) !important;
  border-color: var(--color5) !important;
}
.p-button-info {
  background-color: var(--info) !important;
  border-color: var(--info) !important;
}
.p-button-warning {
  background-color: var(--warning) !important;
  border-color: var(--warning) !important;
}
.p-button-danger {
  background-color: var(--danger) !important;
  border-color: var(--danger) !important;
}

.p-selectbutton .p-button {
    background-color: var(--color1) !important;
    border: 1px solid var(--color2) !important;
    color: var(--font1) !important;
    margin: 2px !important;
    height: 100px !important;
}
.p-selectbutton .p-button:hover {
    color: var(--color5) !important;
    opacity: 1 !important;
}
.p-selectbutton .p-button:focus {
    outline: 0 none !important;
    outline-offset: 0 !important;
    box-shadow: 0 0 0 0.1rem var(--color5);
}
.p-selectbutton .p-button.p-highlight {
    background-color: var(--color11) !important;
}
.p-selectbutton .p-button.p-highlight:hover {
    border-color: var(--color5) !important;
}
.p-dropdown {
  border: 1px solid var(--color1) !important;
}
.p-multiselect {
  background-color: var(--color1) !important;
}
.p-multiselect .p-multiselect-label {
  color: var(--font1);
}
.p-multiselect-panel {
  background-color: var(--color1) !important;
}
.p-multiselect-panel .p-multiselect-header {
  background-color: var(--color2) !important;
}
.p-inputtext {
  background-color: var(--color1) !important;
  border-color: var(--color3) !important;
  color: var(--font1) !important;
}
.p-inputtext:enabled:hover {
  border-color: var(--font1) !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background-color: var(--color2) !important;
  color: var(--font1) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--color2) !important;
  color: var(--font1) !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--green2) !important;
  border-color: var(--green2) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--green1) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--green1) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: var(--font1) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--color2) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--color2) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.08rem var(--green2) !important;
}
.p-inputtext:enabled:focus {
  /* box-shadow: inset 0 0 0 0.08rem var(--green2) !important;
  border-color: var(--green2) !important; */
  box-shadow: unset !important;
  border-color: var(--font1) !important;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: var(--green2) !important;
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 0.08rem var(--green2) !important;
}
.p-multiselect-label {
  white-space: unset !important;
  max-height: 8rem;
  overflow: auto !important;
  z-index: 999 !important;
}
.p-multiselect::-webkit-scrollbar-thumb {
  background: var(--woodward_red) !important;
  border-radius:10px;
  }
.p-multiselect::-webkit-scrollbar-thumb:hover {
  background:var(--woodward_red-light) !important;
  }

.p-dropdown-panel .p-dropdown-items {
  background-color: var(--color2);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  background-color: var(--color2) !important;
  color: var(--font1) !important;
  transition: all 0.2s !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
  background-color: var(--color3) !important;
  color: var(--font1) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--color1) !important;
}
.p-dropdown {
    border: 0.5px solid var(--color3) !important;
}
.p-timeline .p-timeline-event-marker {
  border-color: var(--green2) !important;
}
.p-timeline-event-opposite {
  min-width: 13rem;
  flex: 0 !important;
}
.p-timeline-event-content {
  display: flex;
  height: 2.3rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
  white-space: nowrap !important;
}
.p-inputnumber-button-up, .p-inputnumber-button-down {
  background-color: var(--color3) !important;
  border-color: var(--color3) !important;
}
.p-timeline {
  height: 100% !important;
}
.p-dropdown-panel .p-dropdown-header {
  background-color: var(--color2) !important;

}
/* .p-component-overlay {
  width: 100% !important;
  height: 100% !important;
} */
/* .p-sidebar-mask {
  width: 100% !important;
  height: 100% !important;
} */
/* .p-sidebar-right {
  width: 100% !important;
  height: 90% !important;
  font-size: 14px !important;
} */
.p-sidebar {
  border: 1px solid var(--color2) !important;
  background-color: var(--color3) !important;
  color: var(--font1) !important;
  min-width: 36rem;
}
.p-checkbox .p-checkbox-box {
  background-color: var(--color3) !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: unset !important;
  border: var(--color5);
}
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: unset !important;
}

.p-fileupload .p-fileupload-buttonbar {
  background: var(--color2) !important;
  border: 1px solid var(--color3) !important;
}

.p-fileupload .p-fileupload-content {
  background: var(--color2) !important;
  border: 1px solid var(--color3) !important;
  color: var(--font1) !important;
}

.p-fileupload .p-fileupload-file {
  border: 1px solid var(--color1) !important;

}

.p-fileupload .p-button.p-button-danger {
  background: unset !important;
}

.p-fileupload-file > img {
  visibility: hidden !important;
  content: 'abc' !important;
}

.p-progressbar {
  background: var(--color1) !important;
}

/* equal.js modifires */
.it-toggle {
  background-color: var(--color1) !important;
  border-color: var(--color3) !important;
  color: var(--font1) !important;
}
.it-switch-label {
  color: var(--font1)
}
.it-toggle-slider {
  background-color: var(--color8) !important;
}
.it-toggle:focus {
  outline: 0;
  box-shadow: 0 0 0 1px grey !important;
}
.it-input {
  background-color: var(--color2) !important;
  color: var(--font1) !important;
}
.it-input-wrapper {
  background-color: unset !important;
  border-color: var(--color10) !important;
  box-shadow: none !important;
}
.it-input-wrapper:focus-within {
  outline: 0;
  border: 1px solid var(--color5) !important;
  box-shadow: none !important;
}
.it-input-wrapper input {
  color: #555;
}
.it-input-icon-wrapper {
  background-color: var(--color2) !important;
}
.it-input-label {
  color: var(--font1) !important;
}
.it-input-wrapper--disabled .it-input:disabled, .it-input-wrapper--disabled:hover .it-input:disabled {
  color: grey !important;
  background-color: var(--disabled-color) !important;
}
.mwd-40 {
  color: var(--font1)
}

.it-select-selection {
  border-color: var(--color3) !important;
  box-shadow: none !important;
  background-color: var(--color2) !important;
  color: var(--font1) !important;
}
.it-select-option--focused, .it-select-option:hover {
  background-color: var(--color3) !important;
}
.it-select-list {
  background-color: var(--color2) !important;
}
.it-select li {
  color: var(--font1) !important;
  font: 90% Arial, Helvetica, sans-serif;
}
.it-select-selection--active {
  border-color: var(--woodward_red) !important;
}
.it-select-selection:focus:not(.it-select-selection--disabled) {
  box-shadow: 0 2px 4px rgb(158 33 31 / 40%), 0 0 0 3px rgb(158 33 31 / 20%) !important;
}
.it-select-selection--active>.it-select-arrow {
    color: var(--woodward_red) !important;
}
.it-select-selection--active>.it-select-arrow {
    color: var(--woodward_red) !important;
}
.it-select-option-check {
    background-color: var(--woodward_red) !important;
}
.it-select-selection:focus:not(.it-select-selection--disabled) {
  border-color: var(--woodward_red) !important;
}
.it-select-list::-webkit-scrollbar-thumb {
  background: var(--woodward_red) !important;
  border-radius:10px;
  }
.it-select-list::-webkit-scrollbar-thumb:hover {
  background:var(--woodward_red-light) !important;
  }
.it-select-arrow {
  color: var(--font1) !important;
}
.it-textarea {
  background-color: rgba(255, 255, 255, 0) !important;
  color: var(--font1) !important;
  border-color: var(--green1) !important;
}
.it-textarea:focus {
  box-shadow: none !important;
  border-color: var(--green2) !important;
}
.it-checkbox--primary--checked {
  background-color: var(--green1) !important;
  border-color: var(--green1) !important;
  color: black !important;
}
.it-checkbox--primary:hover {
  border-color: var(--green1) !important;
}
.it-checkbox--primary:active {
  border-color: var(--green1) !important;
}
.it-checkbox--primary--checked:active {
  background-color: unset !important;
}
.it-checkbox-wrapper {
  align-items: center !important;
}
.it-checkbox-input:focus+.it-checkbox--primary {
  border-color: var(--green1) !important;
  box-shadow: unset !important;
}
.multiselect__single {
  background-color: var(--color2) !important;
  color: var(--font1)
}
.multiselect__tags {
  background-color: var(--color2) !important;
  border-color: var(--color6) !important;
}

.multiselect__tags input {
  color: var(--font3) !important;
}
.multiselect__content-wrapper {
  position: absolute !important;
  max-height: 240px !important;
}
.multiselect__input {
  background-color: var(--color2) !important;
}
.multiselect__element {
  background-color: var(--color2) !important;
}
.multiselect__option > span {
  color: var(--font1) !important;
}
.multiselect__option--highlight {
  background-color: var(--color1) !important;
}
.multiselect__option--selected {
  background-color: var(--color3) !important;
}
.multiselect__option span {
  color: #555;
  font: 80% Arial, Helvetica, sans-serif;
}
.p-orderlist-controls {
  display: none !important;
}
.p-orderlist-list {
  max-height: 18rem !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    background-color: #ffffff !important;
}


.loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.625);
}

.backdrop-label {
    font-size: 2.5rem;
    color: var(--font1);
}

/*fieldset*/

.p-fieldset-legend {
  background-color: var(--surface-card) !important;
  border: none !important;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  color: var(--font1) !important;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  box-shadow: none !important;
}

.base-fieldset {
  background-color: var(--surface-card) !important;
  color: var(--font1) !important;
}

/* scroll panel */

.p-scrollpanel .p-scrollpanel-bar:focus {
  box-shadow: none !important;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: var(--color10) !important;
}
.p-sidebar {
  background-color: var(--color1) !important;
}

/* default scrollbar */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--silver);
}

/* accordion, accordiontab */

.p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent !important;
  color: var(--font1) !important;
  border: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-accordion .p-accordion-content {
  background: transparent !important;
  border: none !important;
  color: var(--font1) !important;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d !important;
}


</style>
