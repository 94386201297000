import { databaseURL } from '../firebaseConfig'
import { getUserPreferences } from 'vanilla-cookieconsent'
export default {
    state() {
        return {
            main_database_online: databaseURL + '/users/',
            list_of_simulations: [],
            config: [],
            element_names: [],
            isLoading: true,
            chosed_simulation: null,
            rows: null,
            columns: null,
            scenario_tags: null,
            is_loading: false,
            signal_names_list: null,
            signal_set: null,
            lastElem: null,
            setup_sequence: null,
            build_version: 'cloud', //options: pc, simubox, cloud
            scenarioSpecified: true,
            initialPasswordChanged: true,
        }
    },
    mutations: {
        //setting actuall model (items and its coordinates) and size of grid
        setConfig(state, payload) {
            state.config = payload.configuration
            state.isLoading = payload.isLoading
            state.rows = payload.rows
            state.columns = payload.columns
            state.lastElem = payload.lastElem
            state.scenario_tags = payload.scenario_tags,
            state.setup_sequence = payload.setup_sequence
            state.signal_set = payload.signal_list
        },
        setSignalList(state, payload) {
            state.signal_names_list = payload.signalNamesList
            state.is_loading = false
        },
        //setting list of created simulations (scenarios) can be choosed in settings panel
        setListOfSimulations(state, payload) {
            state.list_of_simulations = payload.list_of_simulations
        },
        //setting actuall model (endpoint in database) from database f.e /simulation1
        setUrl(state, payload) {
            if (getUserPreferences().acceptedCategories.includes('necessary')) {
                localStorage.setItem('scenario', payload.chosed_simulation)
            }
            state.chosed_simulation = payload.chosed_simulation
        },
        //setting load flag, when is_loading is true nothing is shown
        setLoading(state,payload) {
            state.is_loading = payload.loading
        },
        setSignalSet(state, payload) {
            state.signal_set = payload.signal_set
        },
        setScenarioSpecified(state, payload) {
            state.scenarioSpecified = payload.scenarioSpecified
        },
        setEmptyScenarioGrid(state, payload) {
            state.config = payload.config
            state.rows = payload.rows
            state.columns = payload.columns
        },
        setInitialPasswordChanged(state, payload) {
            state.initialPasswordChanged = payload.initialPasswordChanged
        }
    },
    actions: {
        //fetch all data from database and set flag
        ///used in SimulationScreen.vue, Configuartion.vue and here
        async firebase({commit, getters}, force) {
            // check if this scenario exists if not, switch to exisitng one, if there is no scenarios show information
            const testResponse = await fetch(getters.firebase_simu_url + `/grid_size${getters.databaseEndpoint}`)
            const testResponseData = await testResponse.json()
    
            if (testResponseData === null) {
                commit('setLoading', {loading: false}) 
                commit('setScenarioSpecified', {scenarioSpecified: false})
                commit('setUrl', {chosed_simulation: 'No scenario selected'})
                return
            }
            
            commit('setScenarioSpecified', {scenarioSpecified: true})
            
            var elements = []

            commit('setLoading', {loading: true}) 

            let scenarioData = null

            if (force || !getters.listOfSimulations[getters.chosedScenario]) {
                const response = await fetch(getters.firebase_simu_url + getters.databaseEndpoint)
                scenarioData = await response.json()
            } else {
                scenarioData = getters.listOfSimulations[getters.chosedScenario]
            }
            
            const configuration = scenarioData.elements
            const rows = scenarioData.grid_size.split('x')[0]
            const columns = scenarioData.grid_size.split('x')[1]
            const scenario_tags = scenarioData.scenario_tags ? scenarioData.scenario_tags.split(',') : scenarioData.scenario_tags
            const setup_sequence = scenarioData.setup_sequence || null
            const signal_list = scenarioData.signal_list || null
            const sequences_list = scenarioData.sequencer || null



            for (const elem of Object.keys(scenarioData.elements)) {
                elements.push(elem)
            }

            elements.sort((a,b) => {
                return Number(a.split('_')[1]) > Number(b.split('_')[1]) ? 1 : -1
            });

            commit('setConfig', {
                configuration: configuration,
                isLoading: false,
                rows: rows,
                columns: columns,
                scenario_tags: scenario_tags,
                setup_sequence: setup_sequence,
                signal_list: signal_list,
                lastElem: elements.at(-1)
            })
            commit('setListOfSequences', {sequences_list: sequences_list})
            commit('setLoading', {loading: false})
           
        },
        //fetching list of avaiable simulations
        async listOfSimulations({commit,getters}) {

            const response = await fetch(getters.simulationsDatabase+`${getters.databaseEndpoint}`)
            
            const responseData = await response.json()

            await commit('setListOfSimulations', {
                list_of_simulations: responseData
            })
        },
        //request to delete element from database, payload containts index of element in database
        //index in app is equal to index in firebase because application is loading element by element and indexing in the same order
        //used in SimulationScreen.vue
        async deleteElement({getters, dispatch}, payload) {

            //getting name of element with index and saving it to name element
            const element = getters.configKeys[payload.index]

            //deleting item in database with name 'element'
            await fetch(getters.firebase_elements_url + '/' + element + `${getters.databaseEndpoint}`, {
                method: 'DELETE'
            })

            //load simulation view
            await dispatch('firebase', true)
        },
        async moveElement({getters,dispatch}, payload) {
            const element = getters.configKeys[payload.index]

            await fetch(getters.firebase_elements_url + '/' + element + `${getters.databaseEndpoint}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    loc: `this.$refs.box${payload.row}_${payload.column}`
                    })
            })

            await dispatch('firebase', true)
        },
        async createElement({getters, dispatch}, payload) {
            await fetch(getters.firebase_elements_url + '/' + payload.item_id + `${getters.databaseEndpoint}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    loc: payload.loc,
                    signal_commands: payload.signal_commands,
                    signal_names: payload.signal_names,
                    signal_units: payload.signal_units,
                    default_fav: payload.default_fav,
                    type: payload.type,
                    line_type: payload.line_type,
                    line_rotation: payload.line_rotation,
                    is_vertical: payload.is_vertical,
                    diodes: payload.diodes,
                    label_properties: payload.label_properties,
                    name: payload.name
                })
            })
            await dispatch('firebase', true)

        },
        async copyElement({getters,dispatch}, payload) {
            const element = getters.config[payload.index]
            element['loc'] = `this.$refs.box${payload.row}_${payload.column}`

            if (element.signal_names) {
                if (['generator', 'line'].includes(element.type)) {
                    let new_signals = []
                    for (const signal of element.signal_names.split(',')) {
                        let signal_splited = signal.split('_')
                        let signal_number = Number(signal.split('_')[1]) + 1
                        signal_splited[1] = signal_number 
                        new_signals.push(signal_splited.join('_'))
                    }
                    element.signal_names = new_signals.join(',')
                }
                if (['load'].includes(element.type)) {
                    let new_signals = []
                    for (const signal of element.signal_names.split(',')) {
                        let signal_splited = signal.split('_')
                        let signal_number = Number(signal.split('_')[1]) + 1
                        signal_splited[1] = signal_number 
                        new_signals.push(signal_splited.join('_'))
                    }
                    element.signal_names = new_signals.join(',')
                    element.signal_commands = new_signals.join(',')
                }
                if (['breaker'].includes(element.type)) {
                    let signal_splited = element.signal_names.split('_')
                    let command_splited = element.signal_commands.split('_')
                    let signal_number = Number(element.signal_names.split('_')[1]) + 1
                
                    signal_splited[1] = signal_number 
                    command_splited[1] = signal_number

                    element.signal_names = signal_splited.join('_')
                    element.signal_commands = command_splited.join('_')
                }
                if (['easygen', 'ls6'].includes(element.type)) {
                    let new_signals = []
                    for (const signal of element.signal_names.split(',')) {
                        let signal_splited = signal.split('_')
                        let signal_number = Number(signal.split('_')[0].slice(-1)) + 1
                        signal_splited[0] = signal.split('_')[0].slice(0, -1) + signal_number 
                        new_signals.push(signal_splited.join('_'))
                    }
                    element.signal_names = new_signals.join(',')
                    element.signal_commands = new_signals.join(',')
                }
                if (['mains'].includes(element.type)) {
                    let new_signals = []
                    let new_commands = []
                    for (const signal of element.signal_names.split(',')) {
                        let signal_splited = signal.split('_')
                        let signal_number = Number(signal.split('_')[1]) + 1
                        signal_splited[1] = signal_number
                        new_signals.push(signal_splited.join('_'))
                    }
                    if (element.signal_commands) {
                        for (const signal of element.signal_commands.split(',')) {
                            let signal_splited = signal.split('_')
                            let signal_number = Number(signal.split('_')[1]) + 1
                            signal_splited[1] = signal_number
                            new_commands.push(signal_splited.join('_'))
                        }
                        element.signal_commands = new_commands.join(',')
                    }
                    element.signal_names = new_signals.join(',')
                }
            }

            await fetch(getters.firebase_elements_url + '/' + getters.newElementNumber + `${getters.databaseEndpoint}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(element)
            })

            await dispatch('firebase', true)
        },
        async getSignalNames({commit, getters}) {

            commit('setLoading', {loading: true})
            
            //fetching signal names list
            const signalNames = await fetch(getters.firebase_signals_url + `${getters.databaseEndpoint}`)
            const signalNamesList = await signalNames.json();

            commit('setSignalList', {
                signalNamesList: signalNamesList
            })
        },
        async createSignalList({getters}, payload) {

            const timeout = setTimeout(() => {
                payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong, try again', life: 5000});
            }, 3000);

            var obj = {}
            obj[payload.list] = 'Upload api_signals.txt with "+" button '
            await fetch(getters.firebase_signals_url + `${getters.databaseEndpoint}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })

            clearTimeout(timeout)
            payload.vm.$toast.add({severity:'success', summary: 'Success!', detail: `List ${payload.list} has been created.`, life: 5000}); 
        },
        async uploadSignalList({getters,commit}, payload) {

            commit('setLoading', {loading: true})

            const timeout = setTimeout(() => {
                payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong, try again', life: 5000});
            }, 5000);

            var obj = {}
            obj[payload.list] = payload.signal_names
            await fetch(getters.firebase_signals_url + `${getters.databaseEndpoint}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(obj)
            })
            // await dispatch('saveChanges')
            clearTimeout(timeout)
            payload.vm.$toast.add({severity:'success', summary: 'Success!', detail: `List ${payload.list} has been updated.`, life: 5000}); 
            return 
        },
        async deleteSignalList({getters}, payload) {

            const timeout = setTimeout(() => {
                payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong, try again', life: 5000});
            }, 3000);

            await fetch(getters.firebase_signals_url + payload.list + `${getters.databaseEndpoint}`, {
                method: 'DELETE'
            })

            clearTimeout(timeout)
            payload.vm.$toast.add({severity:'success', summary: 'Success!', detail: `List ${payload.list} has been deleted.`, life: 5000});
        },
        async uploadSimulation({getters}, payload)  {
            const timeout = setTimeout(() => {
                payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong, try again', life: 5000});
            }, 3000);

            await fetch(getters.simulationsDatabase + payload.scenario_name + `${getters.databaseEndpoint}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload.body)
            })

            clearTimeout(timeout)
            payload.vm.$toast.add({severity:'success', summary: 'Success!', detail: `Scenario ${payload.scenario_name} has been created.`, life: 5000});
        },
        async createSimulation({getters, dispatch, commit}, payload) {
            await fetch(getters.simulationsDatabase + payload.scenario_name + `${getters.databaseEndpoint}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    elements: {
                        blank: {
                            empty: 'empty'
                        }
                    },
                    grid_size: `${payload.rowNumber}x${payload.columnNumber}`,
                    scenario_tags: payload.scenario_tags.join(','),
                    signal_list: payload.signal_list
                })
            })
            await commit('setUrl', {
                chosed_simulation : payload.scenario_name
            })
            dispatch('listOfSimulations')
            return true
        },
        async copySimulation({getters, dispatch, commit}, payload) {
            if (!payload.scenario_to_copy) {
                return false
            }

            const simulation_to_copy = getters.listOfSimulations[payload.scenario_to_copy]
            //drop reactivity
            const simulation_to_copy_data = JSON.parse(JSON.stringify(simulation_to_copy))

            if (simulation_to_copy_data.scenario_tags.includes('Default')) {
                simulation_to_copy_data.scenario_tags = simulation_to_copy_data.scenario_tags.replace('Default', 'User')
            } else if (simulation_to_copy_data.scenario_tags.length === 0) {
                simulation_to_copy_data.scenario_tags = 'user'
            } else if (!simulation_to_copy_data.scenario_tags.includes('user')) {
                simulation_to_copy_data.scenario_tags = simulation_to_copy_data.scenario_tags += ',user'
            }

            await fetch(getters.simulationsDatabase + payload.scenario_name + `${getters.databaseEndpoint}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(simulation_to_copy_data)
                })
            await commit('setUrl', {
                chosed_simulation : payload.scenario_name
            })

            dispatch('listOfSimulations')
            return true
        },
        async editSimulation({getters, dispatch}, payload) {
            try {
            await fetch(getters.firebase_simu_url + `${getters.databaseEndpoint}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    grid_size: `${payload.row_number}x${payload.column_number}`,
                    scenario_tags: payload.scenario_tags.join(),
                    setup_sequence: payload.setup_sequence,
                    signal_list: payload.signal_list
                })
            })
            } catch(err) {
                console.log(err)
            }
            await dispatch('firebase', true)
            return true
        },
        async deleteSimulation({getters, commit}, payload) {
            const timeout = setTimeout(() => {
                payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong, try again', life: 5000});
            }, 3000);

            await fetch(getters.simulationsDatabase + payload.scenario_name + `${getters.databaseEndpoint}`, {
                method: 'DELETE'
            })

            clearTimeout(timeout)
            commit('setScenarioSpecified', {scenarioSpecified: false})
            commit('setEmptyScenarioGrid', {
                config: [],
                columns: null,
                rows: null
            })
            payload.vm.$toast.add({severity:'success', summary: 'Success!', detail: `Scenario ${payload.scenario_name} has been deleted.`, life: 5000});
        },
        async getScenarioConfiguration({getters}, payload) {
            const response = await fetch(getters.simulationsDatabase + payload.scenario_name + `${getters.databaseEndpoint}`) 
            const responseData = await response.json();
            return responseData
        },
        async fetchInitialPasswordChanged({getters, commit}) {
            const response = await fetch(getters.initialPasswordChangedDatabase + `${getters.databaseEndpoint}`)
            const responseData = await response.json()
            commit('setInitialPasswordChanged', {initialPasswordChanged: responseData})
            return responseData
        }
    },
    getters: {
        //return values of simulation items: signals, coordinates, names and etc.
        //used in SimulationScreen.vue
        config(state) {
            return Object.values(state.config)
        },
        //return names of simulation items
        //used here
        configKeys(state) {
            return Object.keys(state.config)
        },
        //return list of all avaiable simulations created in application
        //used in Configuration.vue
        listOfSimulations(state) {
            return state.list_of_simulations
        },
        mainDatabase(state, getters) {
            return state.main_database_online + getters.user.data.uid + '/'
        },
        //return string with url to firebase with endpoint /simulations
        //used in CreateSimulation.vue and here
        simulationsDatabase(_, getters) {
            return getters.mainDatabase + 'simulations/'
        },
        initialPasswordChangedDatabase(_, getters) {
            return getters.mainDatabase + 'initial_password_changed/'
        },
        firebase_signals_url(_, getters) {
            return getters.mainDatabase + 'signals/'
        },
        //return string with url to firebase with endpoint /simulations/chosedScenario/elements
        //used in AddElement.vue and here
        firebase_elements_url(state, getters) {
            return getters.simulationsDatabase + state.chosed_simulation + '/elements'
        },
        //return string with url to firebase with endpoint /simulations/chosedScenario
        //used here
        firebase_simu_url(state, getters) {
            return getters.simulationsDatabase + state.chosed_simulation
        },
        //return true false if app is fetching data
        //used in SimulationScreen.vue
        isLoading(state) {
            return state.is_loading
        },
        //return grid size
        //used in SimulationScreen.vue
        size(state) {
            return {
                rows: Number(state.rows),
                columns: Number(state.columns)
            }
        },
        scenarioTags(state) {
            return state.scenario_tags
        },
        setupSequence(state) {
            return state.setup_sequence
        },
        signalSet(state) {
            return state.signal_set
        },
        //return list of signal names sets
        //used in configuartion.vue and AddElement.vue
        signalSets(state) {
            return state.signal_names_list
        },
        signalNames(state) {
            return state.signal_set ? state.signal_names_list[state.signal_set] : null
        },
        chosedScenario(state) {
            return state.chosed_simulation
        },
        isSignalListCorrect(state, getters) {
            return getters.chosedScenario === state.signal_set ? true : false
        },
        newElementNumber(state) {
            return state.lastElem !== 'blank' ? `Element_${Number(state.lastElem.split('_')[1]) + 1}` : 'Element_1'
        },
        //new
        databaseEndpoint(state, getters) {
            return state.build_version === 'simubox' ? '.json?ns=web-simhmi' : `.json?auth=${getters.accessToken}`
        },
        buildVersion(state) {
            return state.build_version
        },
        scenarioSpecified(state) {
            return state.scenarioSpecified
        },
        initialPasswordChanged(state) {
            return state.initialPasswordChanged
        }
    }
}