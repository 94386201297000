export default {
    state() {
        return {
            zoom: 0.80,
            theme_mode: 'theme-dark',
            scenario: 'EG3500XTP2_4',
            stop_notification: false
        }
    },
    mutations: {
        //calculating new zoom value
        refreshZoom(state, payload) {
            state.zoom += payload.value
        },
        saveTheme(state, payload) {
            state.theme_mode = payload.theme
        },
        saveScenario(state, payload) {
            state.scenario = payload.scenario
        },
        setNotification(state) {
            state.stop_notification = true
            console.log(state.stop_notification)
        }
    },
    actions: {
        //used in SimulationScreen.vue
        changeZoom(context, value) {
            context.commit('refreshZoom', {value: value})
        },
        setTheme({commit}, payload) {
            const html = document.querySelector('html');
            html.dataset.theme = payload.theme
            commit('saveTheme', {theme: payload.theme})
        },
        setScenario({commit}, payload) {
            commit('saveScenario', {scenario: payload.scenario})
        }
    },
    getters: {
        //returning zoom value
        //used in SimulationScreen.vue
        getZoom(state) {
            return state.zoom
        },
        getTheme(state) {
            return state.theme_mode
        },
        getScenario(state) {
            return state.scenario
        },
        stopNotification(state) {
            return state.stop_notification
        }
    }
}